import React from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';

const ExternalAccount = () => {
    const navigate = useNavigate()
    const { data: externalAccountDAta, dataLoading: externalAccountLoading } = useFetch(endPoints.GET_BY_ROLE);
    // loading bars
    const loading = () => {
        if (externalAccountLoading) {
            return true
        }
        else return false
    }
    const acc_slug=(item)=>{
         return item?.uuid + "_"+item?.bank_name?.trim()?.toLowerCase()?.replaceAll(' ',"-")
        
    }
    
    return (
        <>
            <MainHeader />
        {!loading()&&    <div className="content-body default-height">
          
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">External Accounts</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <h5>Link External Account</h5>
                                                    <ul className="tab-links">
                                                        <li>
                                                            <a onClick={() => navigate("/link-external-account")}  className='cursor-pointer'>
                                                                Link External Account{" "}
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-12 pt-2">
                                                    <h5>Account</h5>

                                                    <ul className="detail-text ext-account" >
                                                        {externalAccountDAta?.data?.length>0 && externalAccountDAta?.data?.map((item, id) => {
                                                            return (
                                                                <li key={id} className='cursor-pointer' onClick={() => { navigate(`/relink-external-account/${acc_slug(item?.attributes)}`,{state:item})}}>
                                                                <span>
                                                                 
                                                                    <span>Bank name: </span>
                                                                    <strong>{item.attributes.bank_name}</strong>
                                                                </span>
                                                                <span>
                                                                 
                                                                    <span>Routing Number: </span>
                                                                    <strong>{item.attributes?.routing_number}</strong>
                                                                </span>
                                                                <span>
                                                                 
                                                                    <span>Last Four: </span>
                                                                    <strong>{item.attributes?.account_number?.slice(-4)}</strong>
                                                                </span>
                                                            </li>)
                                                        })}
                                                        {externalAccountDAta?.data?.length==0&& <p className="">No Data Found</p>}
                                                    </ul>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    )
}

export default ExternalAccount