import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useMutation from '../hooks/useMutation'
import { toast } from 'react-toastify'
import { MyContext } from '../hooks/MyProvider'
import endPoints from '../constants'

export const CreateBusiness = ({ business, editable, onNextClick }) => {
    const navigate = useNavigate();
    const params = useParams();
    const location=useLocation();
    console.log("params==============", location?.pathname?.includes('update-business'));
    
    const { updatebusiness, setUpdateBusiness } = useContext(MyContext);
    const [businessDetail, setBusinessDetail] = useState({
        businessName: business?.legal_name ?? "",
        BDA: business?.dba ?? "",
        webURL: business?.website ?? "",
        entityType: business?.entity_type ?? "",
        ESTDate: business?.formation_date ?? "",
        ein_number: business?.id_number ?? ""
    });
    const [errors, setErrors] = useState({
        businessName: "",
        webURL: "",
        entityType: "",
        ein_number: "",
        ESTDate: ""
    });

    const { data: relationShipTypes, loading, mutate: fetchRelationshipTypes } = useMutation(endPoints.RELATIONSHIP_TYPES);

    useEffect(() => {
        if (!relationShipTypes) {
            fetchRelationshipTypes();
        }
    }, [relationShipTypes]);

    useEffect(() => {
        setBusinessDetail({
            businessName: business?.legal_name ?? "",
            BDA: business?.dba ?? "",
            webURL: business?.website ?? "",
            entityType: business?.entity_type ?? "",
            ESTDate: business?.formation_date ?? "",
            ein_number: business?.id_number ?? ""
        })
    }, [business])

    const [einNumber, setEinNumber] = useState("");
    const formatEIN = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length <= 2) {
            return value;
        } else if (value.length <= 10) {
            return `${value.slice(0, 2)}-${value.slice(2)}`;
        } else {
            return `${value.slice(0, 2)}-${value.slice(2, 9)}`;
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;

        if (name === 'webURL') {
            setBusinessDetail(prevState => ({ ...prevState, [name]: value }));
        } else if (name === 'ein_number') {
            const formattedValue = formatEIN(value);
            setEinNumber(value);
            setBusinessDetail(prevState => ({ ...prevState, [name]: formattedValue }));
        } else {
            setBusinessDetail(prevState => ({ ...prevState, [name]: value }));
        }
        if (name !== 'BDA') {
            if (name === 'ein_number' && value.length !== 10) {
                setErrors(prevState => ({ ...prevState, [name]: "Invalid EIN" }));
            } else if (value.trim() === "") {
                setErrors(prevState => ({ ...prevState, [name]: "Required" }));
            } else {
                setErrors(prevState => ({ ...prevState, [name]: "" }));
            }
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let isError = false;
            const newErrors = { ...errors };

            // Check for empty fields
            for (const key in businessDetail) {
                if (businessDetail.hasOwnProperty(key) && key !== 'BDA') {
                    if (businessDetail[key].trim() === "") {
                        newErrors[key] = "Required";
                        isError = true;
                    } else {
                        // Clear the error if the field is not empty
                        newErrors[key] = "";
                    }
                }
            }

            if (isError) {
                setErrors(newErrors);
                return; // Stop execution if there are errors
            }

            // Proceed with navigation if there are no errors
            let einNum = `${businessDetail?.ein_number?.slice(0, 2)}${businessDetail?.ein_number?.slice(3)}`;
            if (window.location.pathname.includes("update-business")) {
                navigate(`/update-business-address/${params?.b_id}`, { state: { businessDetail: { ...businessDetail, ein_number: Number(einNum) } } });
            } else {
                navigate("/business-address", { state: { businessDetail: { ...businessDetail, ein_number: Number(einNum) } } });
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error || 'An error occurred');
            toast.error(error?.response?.data?.error[0]?.errorDesc || 'An error occurred');
        }
    };
    const handleBlur = (e) => {
        const { name } = e.target;

        if (name === 'webURL') {
            setBusinessDetail(prevState => {
                const value = prevState[name];
                const formattedValue = value.startsWith('http://') || value.startsWith('https://')
                    ? value
                    : `https://${value}`;
                return { ...prevState, [name]: formattedValue };
            });
        }
    };

    const currentDate = new Date().toISOString().split('T')[0];


    return (
        <>
            {!loading && (
                <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="mb-1 form-label">
                            Legal Business Name *
                        </label>
                        <input
                            name='businessName'
                            type="text"
                            className="form-control"
                            placeholder="Legal Business Name"
                            value={businessDetail?.businessName}
                            onChange={(e) => { handleChange(e) }}
                            disabled={!editable}
                        />
                        {errors.businessName && <small className="text-danger">Required</small>}
                    </div>
                    <div className="form-group">
                        <label className="mb-1 form-label">DBA</label>
                        <input
                            name='BDA'
                            type="text"
                            className="form-control"
                            placeholder="DBA"
                            value={businessDetail?.BDA}
                            onChange={(e) => { handleChange(e) }}
                            disabled={!editable}
                        />
                    </div>
                    <div className="form-group">
                        <label className="mb-1 form-label">Web URL *</label>
                        <input
                            name='webURL'
                            type="text"
                            className="form-control"
                            placeholder="Web URL"
                            value={businessDetail.webURL}
                            onChange={(e) => { handleChange(e) }}
                            onBlur={(e) => handleBlur(e)}
                            disabled={!editable}
                        />
                        {errors.webURL && <small className="text-danger">Required</small>}
                    </div>

                    <div className="form-group">
                        <label className="mb-1 form-label">EIN Number *</label>
                        <input
                            name='ein_number'
                            type="text"
                            className="form-control"
                            placeholder="EIN"
                            value={businessDetail?.ein_number}
                            maxLength="10"
                            onChange={(e) => { handleChange(e); }}
                            disabled={!editable}
                        />
                        {errors.ein_number && <small className="text-danger">Required</small>}
                    </div>
                    <div className="form-group">
                        <label className="mb-1 form-label">
                            Entity Type *
                        </label>
                        <select
                            name='entityType'
                            className="form-control"
                            aria-label="Default select example"
                            value={businessDetail?.entityType}
                            onChange={(e) => { handleChange(e) }}
                            disabled={location?.pathname?.includes('update-business') || !editable}
                        >
                            <option value="" disabled>Select an Option</option>
                            {relationShipTypes && relationShipTypes?.map((item, id) => (
                                <option value={item?.value ?? item?.type} key={id}>{item?.description}</option>
                            ))}
                        </select>
                        {errors.entityType && <small className="text-danger">Required</small>}
                    </div>
                    <div className="form-group">
                        <label className="mb-1 form-label">EST Date *</label>
                        <input
                            max={currentDate}
                            name='ESTDate'
                            type="date"
                            className="form-control"
                            placeholder="EST Date"
                            value={businessDetail.ESTDate}
                            onChange={(e) => { handleChange(e) }}
                            disabled={location?.pathname?.includes('update-business') || !editable}

                        />
                        {errors.ESTDate && <small className="text-danger">Required</small>}
                    </div>
                    <div className="text-center">
                        <button
                            type="button"
                            className="btn"
                            style={{ width: '9vw' }}
                            onClick={() => { 
                                const datas = Object.values(errors)
                                let validate = false;
                                for( let key in datas) {
                                    validate =  datas[key].length==0;
                                    console.log("validate", validate);
                                    if(!validate) {
                                        break;
                                    }
                                } 
                                if(validate){

                                    onNextClick(); setUpdateBusiness(businessDetail)
                                }
                             }}
                        >
                            Next
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};
