import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { usePlaidLink } from 'react-plaid-link';
import baseUrl from '../baseUrl';
import Sidebar from '../components/Sidebar';

// ...



const LinkExternalAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [plaidToken, setPlaidToken] = useState("");
    const [accData, setAccData] = useState({
        "label": "",
        "account_number": "",
        "routing_number": "",
        "purpose": "",
        "bank_name": "",
        "account_type": "",
        "amount": "",
        "type": "outgoing",
        "account_id": "",
        "contact_id": "",
        "business_id": "",
        "sent_from": "",
        "plaid_account_id": "",
        "plaid_access_token": "",
    });

    const [errors, setErrors] = useState({});
    const { data: business, dataLoading: business_Loading } = useFetch(endPoints.ALL_BUSINESS);
    // const { data, dataLoading } = useFetch(`${endPoints.ACH_PAYMENTS}?type=outgoing%2Cexternal%2Cincoming&account_id=${location?.state?.businessId}&contact_id=${location?.state?.contactId}`);

    useEffect(() => {

        get_client_access_token();
    }, []);

    const get_client_access_token = async () => {
        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_TOKEN_REQUEST}`,
                { redirect_uri:"redirect"},
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                setPlaidToken(resp?.data)
            }
          
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }

    const GetPlaidAuthDetails = async (token, accountId) => {
        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.PLAID_AUTH}`,
                {
                    "public_token": token,
                    "plaid_account_id": accountId,
                    "payment_type": "external"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                setAccData((prev) => ({
                    ...prev,
                    "account_number": resp?.data?.auth?.numbers?.ach?.[0]?.account,
                    "routing_number": resp?.data?.auth?.numbers?.ach?.[0]?.routing,
                    "account_type": resp?.data?.auth?.accounts?.[0]?.subtype,
                    "plaid_account_id": accountId,
                    "plaid_access_token": resp?.data?.access_token,

                }))
            }
          
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    const { open, ready } = usePlaidLink({
        token: plaidToken?.link_token, // pass the dynamic token prop here
        onSuccess: (public_token, metadata) => {
            // send public_token to server
           
            GetPlaidAuthDetails(public_token, metadata?.account_id)
        },
        onExit: (data) => {
            navigate("/external-account", { state: location?.state });
        }
    });


    useEffect(() => {
        if (plaidToken?.link_token && ready) {
       
            open(); // call open() when the token is available and the link is ready
        }
    }, [plaidToken?.link_token, ready]);

    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setAccData({ ...accData, [name]: value });
        if ((name === "label") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }

        if ((name === "bank_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }
        if ((name === "account_number") && value?.length != 16) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid account number"
            }));
            return;
        }
        if ((name === "routing_number") && value?.length != 9) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid routing number"
            }));
            return;
        }
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        if (!accData.label) validationErrors.label = "Name is required";
        if (!accData.account_number) validationErrors.account_number = "Account Number is required";
        if (!accData.routing_number) validationErrors.routing_number = "Routing Number is required";
        if (!accData.account_type) validationErrors.account_type = "Account Type is required";
        if (!accData.bank_name) validationErrors.bank_name = "Bank Name is required";
        if (!accData.business_id) validationErrors.business_id = "BusinessID is required";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            let resultData = {
                "label": accData?.label,
                "account_number": accData?.account_number,
                "routing_number": accData?.routing_number,
                "bank_name": accData?.bank_name,
                "account_type": accData?.account_type,
                "business_id": accData?.business_id,
                "plaid_account_id": accData?.plaid_account_id,
                "plaid_access_token": accData?.plaid_access_token,
            };

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.LINK_EXTERNAL_ACCOUNT_USER_ID}`, resultData,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );

            if (resp.status === 200) {
                toast.success("Transaction Successful ");
                navigate("/external-account", { state: location?.state });
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }

    const selectAccount = (bId) => {
        let acc = business?.filter((item) => {
            if (bId == item?.uuid) {
                return item
            }
        })
        setAccData({ ...accData, business_id: acc?.[0]?.uuid });

    }


    return (
        <>
            <MainHeader />
            <div className="authincation content-body default-height">
                <div className="container-fluid h-100">
                    <div className="row ">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">ACH Payment</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Name</label>
                                                                <input
                                                                    onChange={handleChange}
                                                                    value={accData?.label}
                                                                    name='label'
                                                                    type="text"
                                                                    className={`form-control `}
                                                                    placeholder="Name"
                                                                />
                                                                {errors.label && <div className="text-danger">{errors.label}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Account Number</label>
                                                                <div className="input-group">
                                                                    <span className={`form-control`}>{accData?.account_number}</span>


                                                                </div>
                                                                {errors.account_number && <div className="text-danger">{errors.account_number}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Routing Number</label>
                                                                <span className={`form-control `} >{accData?.routing_number}</span>

                                                                {errors.routing_number && <div className="text-danger">{errors.routing_number}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Account Type</label>
                                                                <span className={`form-control `}>{accData?.account_type}</span>
                                                                {errors.account_type && <div className="text-danger">{errors.account_type}</div>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Bank</label>
                                                                <input
                                                                    onChange={handleChange}
                                                                    value={accData?.bank_name}
                                                                    name='bank_name'
                                                                    type="text"
                                                                    className={`form-control`}
                                                                    placeholder="Bank"
                                                                />
                                                                {errors.bank_name && <div className="text-danger">{errors.bank_name}</div>}
                                                            </div>

                                                            {/* business listing */}
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Business </label>
                                                                <select
                                                                    name='business_id'
                                                                    className="form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => { selectAccount(e.target.value); handleChange(e); }}
                                                                >
                                                                    <option selected="">Select Business </option>
                                                                    {business?.length && business?.map((item) => {
                                                                        return (
                                                                            <option value={item?.uuid} key={item?.id}>{item?.legal_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {errors?.business_id && (
                                                                    <span className="text-danger">{errors.business_id}</span>
                                                                )}
                                                            </div>


                                                            <div className="text-center">
                                                                <button type="submit" className="btn">
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LinkExternalAccount;
