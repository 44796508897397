import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { useAuth } from "./AuthContext";

const Sidebar = () => {
  const params=useParams();
  const { userRole } = useAuth();
  const [activeTab,setActiveTab]=useState("");
 const navigate=useNavigate();
  const {  show } = useIntercom();
  const handleSignOut = () => {
    localStorage.clear();
    Cookies.remove('url');
    navigate("/login", { replace: true });

  };
useEffect(()=>{
console.log("routes are changing on",window.location.pathname);
  setActiveTab(window.location.pathname)
}, [window.location.pathname])




const [fixedSidebar, setFixedSidebar] = useState(false);

useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY >= 150) {
            setFixedSidebar(true);
        } else {
            setFixedSidebar(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);



  return (
   
    <div className="col-xl-2 col-md-3 left-side-bar">
      {/* <div className="side-bar"> */}
      <div className={`side-bar ${fixedSidebar ? 'fixed-Sidebar' : ''}`}>
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2">
          <strong className="fs-5 d-sm-inline">Main Menu</strong>
          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
            id="menu"
          >
            {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

            <li className="nav-item">
              <NavLink to="/staff-list" className="nav-link align-middle px-0">
                <i className="fs-4 bi-house" />
                <span className="ms-1  d-sm-inline"> Staff</span>
              </NavLink>
            </li>}
            <li>
              <Link
                to="#submenu1"
                data-bs-toggle="collapse"
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-speedometer2" />
                <span className="ms-1  d-sm-inline">
                 Accounts <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </Link>
              <div 
                className="collapse nav ms-1 drop-menus"
                id="submenu1"
                data-bs-parent="#menu"
              >
              <ul>
                  {userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" &&

                <li>
                      <NavLink to="/external-account" className="nav-link px-0">
                    <span className="">External Accounts</span>
                      </NavLink>
                </li>}
                <li>
                    <NavLink to={'/accounts'} className="nav-link px-0 cursor-pointer" >
                    
                    <span className="">Business Accounts</span>
                    </NavLink>
                </li>
              </ul>
              </div>
            </li>
            <li>
              <Link href="#" className="nav-link px-0 align-middle" onClick={show}>
                <i className="fs-4 bi-table" />
                <span className="ms-1  d-sm-inline"> Get Help</span>
              </Link>
            </li>
            <li>
              <NavLink
                to="/"
          
                className="nav-link px-0 align-middle "
              >
                <i className="fs-4 bi-bootstrap" />
                <span className="ms-1  d-sm-inline"> Business</span>
              </NavLink>
            </li>
            <li>
              <Link
                to="https://skysystemz.com/SkyFi_Privacy_Disclosures"
          
                className="nav-link px-0 align-middle"
              >
                <i className="fs-4 bi-grid" />
                <span className="ms-1  d-sm-inline"> Disclosures</span>
              </Link>
            </li>
            <li>
              <a  className="cursor-pointer nav-link px-0 align-middle" onClick={handleSignOut}>
                <i className="fs-4 bi-people" />
                <span className="ms-1  d-sm-inline"> Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
