import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import baseUrl from '../baseUrl';
import endPoints from '../constants';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Sidebar from '../components/Sidebar';

const CardDetails = () => {
const location=useLocation();
const [token,setToken]=useState(null)
    const get_client_access_token=async()=>{
        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/${endPoints.MARQETA_CLIENT_ACCESS_TOKEN}`,
                {id:location?.state?.cardId},
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if(resp.status==200){
                
                setToken(resp?.data?.token)
                window.open(`${process.env.REACT_APP_BASE_URL}${endPoints.CARD_DETAIL}/${resp?.data?.token}`, '_blank', 'noopener,noreferrer');
                
            }
        
        } catch (error) {
            console.log(error);
             toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);

        }
    }
    useEffect(() => {
        get_client_access_token()
    }, [])
  return (
  <>
  <MainHeader />
          <div className="authincation content-body default-height">
              <div className="container-fluid h-100">
                  <div className="row ">
                      <Sidebar />
                      <div className="col-xl-10 col-md-9 m-auto main-page">
                          <div className="row ">
                              <div className="col-lg-8 col-md-10">
                                  <div className="card login-part">
                                      <div className="authincation-content">
                                          <div className="row no-gutters">
                                              <div className="col-xl-12">
                                                  <div className="auth-form">
                                                      <h4 className="mb-4">Card Details</h4>
                                                      <form action="#" className="mt-4">
                                                          <div className="form-group">
                                                              <label className="mb-1 form-label">
                                                                  {" "}
                                                                  Card Number
                                                              </label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  defaultValue="4444 4444 4444 4444"
                                                              />
                                                          </div>
                                                          <div className="form-group">
                                                              <label className="mb-1 form-label"> EXP</label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  defaultValue="03 / 28"
                                                              />
                                                          </div>
                                                          <div className="form-group">
                                                              <label className="mb-1 form-label"> CVV</label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder={318}
                                                              />
                                                          </div>
                                                      </form>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        {token&&  <iframe src={`${process.env.REACT_APP_BASE_URL}/${endPoints.CARD_DETAIL}/${token}`} frameborder="0">{console.log(`${process.env.REACT_APP_BASE_URL}/${endPoints.CARD_DETAIL}/${token}`)}</iframe>}

  </>
  )
}

export default CardDetails