import React, { useEffect } from 'react'
import MainHeader from '../components/MainHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
import Cookies from 'js-cookie';
import Sidebar from '../components/Sidebar';

const MoveFundsToAnotherBank = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params=useParams();

    const { data, dataLoading } = useFetch(`${endPoints.ACH_PAYMENTS}?type=incoming%2Cexternal&account_id=${params?.acc_id}`);
    // useEffect(()=>{
    //    Cookies.set("red-url",window.location.pathname);
    //    console.log(window.location.pathname);
    // },[])
    // api/v3/ach_payments?type=incoming%2Cexternal&account_id=
    return (
        <>
            <MainHeader />
          {!dataLoading &&  <div className="content-body default-height">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">External Accounts</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                <div className="col-xl-12 " >
                                                    <h5>Account</h5>
                                                    <ul className="detail-text ext-account">
                                                        {data?.data?.length>0 && data?.data?.map((item, id) => {
                                                            return (
                                                                <li className='cursor-pointer' key={item?.id} onClick={() => { navigate(`/move-fund-to-another-account/${params?.acc_id}/fund-transaction/${item?.attributes?.uuid}_${item?.attributes?.bank_name?.trim()?.replaceAll(" ","-")?.toLowerCase()}`) }}>
                                                                    <span>
                                                                        <span>Bank name: </span>
                                                                        <strong>{item?.attributes?.bank_name}</strong>
                                                                    </span>
                                                                    <span>
                                                                        <span>Routing Number: </span>
                                                                        <strong>{item?.attributes?.routing_number}</strong>
                                                                    </span>
                                                                    <span>
                                                                        <span>Last Four: </span>
                                                                        <strong>{item?.attributes?.account_number?.slice(-4)}</strong>
                                                                    </span>
                                                                </li>

                                                            )
                                                        })}
                                                        {data?.data?.length == 0 && <p className="">No Data Found</p>}
                                                    </ul>
                                                </div>

                                                <div className="col-xl-12 pt-4">
                                                    <h5>Link External Account</h5>
                                                    <ul className="tab-links">
                                                        <li>
                                                        <a className='cursor-pointer' href='javascript:void(0)' onClick={() => { navigate(`/link-external-account-detail/${params?.acc_id}`, { state: { accountId: location?.state?.uuid,...location?.state } }) }}>
                                                 
                                                                Link External Account{" "}
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />
        </>
    )
}

export default MoveFundsToAnotherBank