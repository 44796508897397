import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import {  useNavigate, useParams } from 'react-router-dom'
import useFetch from '../hooks/useFetch'
import endPoints from '../constants'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useScrollToTop } from '../hooks/useScrollToTop'
import { Bars } from 'react-loader-spinner'
import CurrencyInput from 'react-currency-input-field'
import { useAuth } from '../components/AuthContext'
import Sidebar from '../components/Sidebar'
const CardSection = () => {
    const { userRole, loading:roleLoading } = useAuth();


    useScrollToTop();
    const navigate = useNavigate();
    const params = useParams();
    const { data: cards, dataLoading: cardsLoading } = useFetch(`${endPoints.GET_CARDS_BY_ID}/${params?.card_id}`);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [viweInfoLoading, setViweInfoLoading]=useState(false);
    const [closeLimitModal,setCloseLimitModal]=useState(false);
    const [openLimitModal, setOpenlimitModal] = useState(false);
    const onOpenLimitModal = () => setOpenlimitModal(true);
    const onCloseLimitModal = () => setOpenlimitModal(false);
    const handleDeleteCard = async () => {
        try {
            setLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/${endPoints.GET_CARDS_BY_ID}/${params?.card_id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setLoading(false);
            if (resp?.status == 200) {
                toast.success("Card deleted");
                navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards`)
            }

        } catch (error) {
            setLoading(false)
            console.log(error)
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (count > 0 && count < 4) {
            get_client_access_token();
        }
    }, [count])
    const get_client_access_token = async () => {
        try {
            setViweInfoLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/${endPoints.MARQETA_CLIENT_ACCESS_TOKEN}`,
                { id: params?.card_id },
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                window.open(`${process.env.REACT_APP_BASE_URL}${endPoints.CARD_DETAIL}/${resp?.data?.token}`, '_blank', 'noopener,noreferrer');
            }
            setViweInfoLoading(false);
        } catch (error) {
            setViweInfoLoading(false);
            if (error?.response?.data?.msg == "Unable to get client access token") {
                setCount(count + 1);
                
            }
            console.log(error)
            toast.error(error?.response?.data?.msg);
            toast.error(error?.response?.data?.msg[0]?.errorDesc);

        }
    }
    // SET PIN
    const [countPin, setCountPin] = useState(0);
    const [pinLoading, setPinLoading] = useState(false);
    useEffect(() => {
        if (countPin > 0 && countPin < 4) {
            set_pin();
        }
        if (countPin == 4) {
            toast.error("Please try again!")
        }
    }, [countPin])
    const set_pin = async () => {
        try {
            setPinLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/${endPoints.SET_PIN}/${params?.card_id}/set_pin_url`,
              
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                window.open(`${resp?.data?.url}`, '_blank', 'noopener,noreferrer');
            }
            setPinLoading(false);
        } catch (error) {
            setPinLoading(false);
            if (error?.response?.data?.msg == "Unable to get client access token" || error?.response?.data?.error =="Unable to obtain one time auth token") {
                setCountPin(countPin + 1);
            }
            else{
                console.log(error);
                toast.error(error?.response?.data?.error);
                toast.error(error?.response?.data?.error[0]?.errorDesc);
            }

        }
    }
    // format date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${year}`;
    };
    // update card status
    const [cardStatusLoading, setCardStatusLoading] = useState(false);
    const [cardStatus, setCardStatus] = useState(false);

    useEffect(() => {
        if (cards?.data?.attributes?.status === "ACTIVE") {
            setCardStatus(true);
        } else {
            setCardStatus(false);
        }
    }, [cards])
    const handleCardStatus = (e) => {

        if (e.target.checked == true) {
            setCardStatus(true)
            update_card_status("activate")
        }
        else {
            setCardStatus(false)
            update_card_status("suspend")

        }
    }
    const update_card_status = async (type) => {
        try {
            setCardStatusLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}/${endPoints.GET_CARDS_BY_ID}/${params?.card_id}/update_card_status`,
                { action_type: type },
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                toast.success(resp?.data?.msg)
            }
            // console.log(resp);
            setCardStatusLoading(false);
        } catch (error) {
            setCardStatusLoading(false);
            if (error.response.status == 401) {
                localStorage.clear();

                navigate('/login');
                return;
            }
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);

        }
    }

    // set limit functionality

    const [limit, setLimit] = useState({
        velocity_window_type: "Day",
        amount: ""
    });
    const [limitLoading, setLimitLoading] = useState(false)
    const [limitError, setLimitError] = useState({
        amount: "",
        velocity_window_type: ""
    })

    const handleOptionChange = (name,value) => {
         setLimit({ ...limit, [name]: value }); 
        setLimitError({...limitError,[name]:""})
    };
    const handleSetLimit = async (e) => {
        e.preventDefault();
        setCloseLimitModal(true);

        if (!limit?.amount && !limit?.velocity_window_type) {
            setLimitError({ ...limitError, amount: "Required", velocity_window_type: "Required" })
            return;
        }
        else if (!limit?.amount) {
            setLimitError({ ...limitError, amount: "Required" })
            return;
        }
        else if (!limit?.velocity_window_type) {
            setLimitError({ ...limitError, velocity_window_type: "Required" })
            return;
        }
        try {
            setLimitLoading(true);
            let data = {
                ...limit, id: params?.card_id
            }
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.SET_CARD_LIMIT}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setLimitLoading(false);
            if (resp.status == 200) {
                
                onCloseLimitModal();
                toast.success("Card Limit Successfully Set");
                setLimit({
                    velocity_window_type: "Day",
                    amount: ""
                })
            }
        } catch (error) {
            setLimitLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    return (
        <>
            <MainHeader />

            {!cardsLoading && <div className="content-body default-height">
                {/* row */}
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">Card Details</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="tab-links">
                                                <div className="row">
                                                    <div className="col-md-12 mb-5 dynamic-card">
                                                        {cards && cards?.data?.attributes?.brand == "visa" ? <div className="card-visa">
                                                            <div className="Business">
                                                                <h2>
                                                                    Business <span>{cards && cards?.data?.attributes?.cardType}</span>
                                                                </h2>
                                                            </div>
                                                            <div className="card-number">
                                                                **** **** **** {cards && cards?.data?.attributes?.lastFour}
                                                            </div>
                                                            <div className="exp-date-row">
                                                                <div className="exp-date">
                                                                    Exp Date<strong>{formatDate(cards && cards?.data?.attributes?.expireDtm)}</strong>
                                                                </div>
                                                                <div className="card-active setting-toggle">
                                                                    <div className="checkbox">
                                                                        <input
                                                                            checked={cardStatus}

                                                                            onChange={(e) => handleCardStatus(e)}
                                                                            type="checkbox"
                                                                            defaultChecked={cardStatus}
                                                                            id="switch2"
                                                                        />
                                                                        <label htmlFor="switch2" />
                                                                    </div>{" "}
                                                                    <strong>Active</strong>
                                                                </div>
                                                            </div>
                                                            <div className="no-limit-set">{cards && cards?.data?.attributes?.card_holder_name}</div>
                                                        </div> :
                                                            <div className="card-master">
                                                                <div className="Business">
                                                                    <h2>
                                                                        Business <span>{cards && cards?.data?.attributes?.cardType}</span>
                                                                    </h2>
                                                                </div>
                                                                <div className="card-number">
                                                                    **** **** **** {cards && cards?.data?.attributes?.lastFour}
                                                                </div>
                                                                <div className="exp-date-row">
                                                                    <div className="exp-date">
                                                                        Exp Date<strong>{formatDate(cards && cards?.data?.attributes?.expireDtm)}</strong>
                                                                    </div>
                                                                    <div className="card-active setting-toggle">
                                                                        <div className="checkbox">
                                                                            <input
                                                                                checked={cardStatus}

                                                                                onChange={(e) => handleCardStatus(e)}
                                                                                type="checkbox"
                                                                                defaultChecked={cardStatus}
                                                                                id="switch1"
                                                                            />
                                                                            <label htmlFor="switch1" />
                                                                        </div>{" "}
                                                                        <strong>Active</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="no-limit-set">{cards && cards?.data?.attributes?.card_holder_name}</div>
                                                            </div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <a onClick={() => { get_client_access_token() }} className='cursor-pointer'>                                                        
                                                           {viweInfoLoading?"Loading":" View Card Information:"} {cards?.data?.attributes?.lastFour}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                    {userRole !="card_only" &&  <div className="col-md-6">
                                                        {/* `/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/card-section` */}
                                                        <button onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/card-section/billing-address`) }}>
                                                            Billing Address
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>}
                                                    {userRole != "view_account" && userRole != "card_only" &&      <div className="col-md-6">
                                                        <button onClick={onOpenLimitModal}>
                                                        {/* <button data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                                                            Spending Limit: $Amount{" "}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>}
                                                    {userRole !="view_account" &&   <div className="col-md-6">
                                                        <a onClick={() => { set_pin() }} className='cursor-pointer'>
                                                            {/* <a onClick={()=>{navigate("/set-pin")}} className='cursor-pointer'> */}
                                                            {pinLoading ? "Loading" : " Set PIN"}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>}
                                                    {userRole != "view_account" &&   <div className="col-md-6 cursor-pointer" onClick={onOpenModal}>
                                                        <a className='cursor-pointer'>
                                                            Cancel Card
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>}
                                                    <div className="col-md-6">
                                                        <button onClick={() => { navigate(`/card-transaction/${params?.acc_label?.split("_")[0]}/${params?.card_id}_card`) }}>
                                                            Card Transactions{" "}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <a className="cursor-pointer">
                                                            Get Help{" "}
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={cardsLoading}
            />
            {/* delete card modal */}
            <Modal open={open} onClose={onCloseModal} center>
                <div className="p-5">
                    <div className="">
                        <h4>Do you want to delete this card</h4>
                        <button className='btn-save mx-3' onClick={handleDeleteCard} disabled={loading}> {loading ? "Loading" : "Sure"}</button>
                        <button className='btn-cancel' onClick={onCloseModal}>Cancel</button>
                    </div>

                </div>
            </Modal>
            {/* --------- */}
            {/* set limit modal */}



           
            <Modal open={openLimitModal} onClose={onCloseLimitModal} center>
             
                    <div className="card-limit-popup">
                      <form className='modal-content'>
                            <div className="modal-body">
                                <div className="modal-row">
                                    <h3>Card Limit</h3>
                                </div>

                                <div className="modal-row">
                                    <label>Limit Type</label>
                                    <div className="btn-group col-xs-12">
                                        <label className="option">
                                            <input type="radio" name="velocity_window_type"  value="Day"
                                                checked={limit?.velocity_window_type === 'Day'}
                                                onChange={(e)=>handleOptionChange(e.target.name,e.target.value)} />
                                            <span className="btn btn-warning btn-option">Day</span>
                                        </label>
                                        <label className="option">
                                            <input type="radio" name="velocity_window_type" value="Month"
                                                checked={limit?.velocity_window_type === 'Month'}
                                                onChange={(e)=>handleOptionChange(e.target.name,e.target.value)} />
                                            <span className="btn btn-warning btn-option">Month</span>
                                        </label>
                                        <label className="option">
                                            <input type="radio" name="velocity_window_type" value="Year"
                                                checked={limit?.velocity_window_type === 'Year'}
                                                onChange={(e)=>handleOptionChange(e.target.name,e.target.value)} />
                                            <span className="btn btn-warning btn-option">Year</span>
                                        </label>
                                    </div>
                                    {limitError?.velocity_window_type && <small className='text-danger'>Required</small>}
                                </div>


                                <div className="form-group card-lmt modal-row">
                                    <label className="mb-1 form-label"> Amount</label>
                                  
                                    <CurrencyInput
                                        className={`form-control `}
                                        name="amount"
                                        prefix="$"
                                        placeholder="Amount"
                                        decimalsLimit={2}
                                        // value={formData.amount}
                                        onValueChange={(value, name) => handleOptionChange(name, value)}
                                    />
                                        {limitError?.amount && <small className='text-danger'>Required</small>}
                                </div>
                            <div className="modal-foter">
                                <button type="button" disabled={limitLoading} className="btn"  onClick={handleSetLimit} >
                                    {limitLoading ? "Loading" : "Save"}
                                </button>
                                <button type="button" className="btn" onClick={onCloseLimitModal}>
                                    Cancel
                                </button>
                            </div>
                            </div>
                        </form>
                        {/* <button className='btn btn-primary' onClick={onCloseModal}>Cancel</button>
                        <button className='btn btn-primary mx-3' onClick={handleDeleteCard} disabled={loading}> {loading ? "Loading" : "Sure"}</button> */}
                    </div>

            
            </Modal>
        </>
    )
}

export default CardSection