import React, { useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import { Bars } from "react-loader-spinner";
import { useAuth } from "../components/AuthContext";
import { decryptData } from "../hooks/encryptionUtils";
import Breadcrumb from "../components/Breadcrumb";
import Sidebar from "../components/Sidebar";
const Accounts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param_acc_name = useParams();

  const [fetchacc, setFetchacc] = useState(0);
  // const [busines_id, setSlug_bid] = useState("");
  const [showAcnts, setShowAcnts] = useState(false);
  const [customerGroup, setCustomerGroup] = useState(false);

  let busines_id = param_acc_name?.name?.split("_")[0];
  const { userRole } = useAuth();

  useEffect(() => {
    if (param_acc_name) {
      setFetchacc(fetchacc + 1);
    }
  }, [param_acc_name]);
  useEffect(() => {
    if (busines_id) {
      setShowAcnts(false);
    }

  }, []);
  const fetchUrl = param_acc_name?.name
    ? `${endPoints.GET_BUSINESS_ACCOUNT}/${busines_id}`
    : `${endPoints.ALL_ACCOUNTS}`;

  const { data, dataLoading } = useFetch(fetchUrl, fetchacc);

  const showAccountDetails = (id, label) => {
    let slug_label = id + "_" + label?.trim()?.replaceAll(" ", "-");
    navigate(`/${param_acc_name?.name}/accounts/${slug_label}`);
  };
  const shortName = (name) => {
    name = name?.trim()?.split(" ");
    let res =
      name?.length > 1
        ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase()
        : name[0][0]?.toUpperCase();

    return res;
  };

  useEffect(() => {
    let userData = localStorage.getItem("user");
    if (userData) {
      try {
        const decrypted = decryptData(userData);
        if (data?.account?.length > 0 && decrypted?.customerGroup == 2) {
          setCustomerGroup(false);
        } else setCustomerGroup(true);
        // setUserRole(decrypted);
      } catch (error) {
        console.error("Decryption error:", error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [data]);
  // breadcrumb routes
  const routes = [
    { name: 'Home', path: '/' },
    { name: `${window.location.pathname === "/accounts" ? "Accounts" :"Business Accounts"}`, path: '' },
  ];


  return (
    <>
      <MainHeader />
      {!dataLoading && (
        <div className="content-body default-height">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-xl-10 col-md-9 m-auto main-page">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card plan-list">
                      <Breadcrumb routes={routes} />
                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                        <div className="me-auto pe-3 mb-3">
                          <h4 className="text-black fs-26 mb-0">
                            {!busines_id ? "Accounts" : "Business Accounts"}
                          </h4>
                        </div>
                        <div className="d-flex gap-2 justify-content-between justify-content-sm-end">
                          <div className="edit-account  cursor-pointer" onClick={() => { navigate(`/register/rollfi/${busines_id}`) }}><a >Sign up for RollFI</a> </div>
                        {(userRole =="admin"|| userRole=="user") &&<div className="edit-account  cursor-pointer" onClick={() => { navigate(`/update-business/${param_acc_name?.name?.split("_")[0]}`) }}><a >Edit</a> </div>}
                        {busines_id &&
                          userRole != "admin" &&
                          userRole != "edit_account" &&
                          userRole != "view_account" &&
                          userRole != "card_only" &&
                          customerGroup && (
                            <div className=" create-account">
                              <a className="cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    `/${param_acc_name?.name}/adding-account`,
                                    {
                                      state: {
                                        businessId: busines_id,
                                        businessData: location?.state?.item,
                                      },
                                    }
                                  );
                                }}
                              >
                                Create New Account
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-body  pt-2">
                        <div className="row">
                          {/* all accounts */}
                          {!showAcnts &&
                            data?.data?.length > 0 &&
                            data?.data?.map((item) => {
                              return (
                                <div
                                  className="col-md-4 col-sm-6"
                                  key={item.id}
                                >
                                  <div
                                    className="flex-wrap align-items-center card-box cursor-pointer"
                                    onClick={() => {
                                      showAccountDetails(
                                        item.attributes.uuid,
                                        item?.attributes?.label
                                      );
                                    }}
                                  >
                                    <div className="col-xl-12 align-items-center">
                                      <div className="list-icons">
                                        {shortName(item?.attributes?.label)}
                                      </div>
                                      <div className="info mb-3 mt-3">
                                        <h4 className="fs-20 mb-0 text-black text-capitalize">
                                          {item.attributes.label}
                                        </h4>
                                        <span>
                                          xxxx-
                                          {item?.attributes?.finxact_party_account_num?.slice(
                                            -4
                                          )}
                                        </span>
                                        <h5 className="mt-2">${Number(item?.attributes?.remaining_balance).toLocaleString('en-US')}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {data?.data?.length == 0 && (
                            <p className="">No Data Found</p>
                          )}
                          {/* business accounts */}
                          {!showAcnts &&
                            data?.account?.length > 0 &&
                            data?.account?.map((item) => {
                              return (
                                <div
                                  className="col-md-4 col-sm-6"
                                  key={item.id}
                                >
                                  <div
                                    className="flex-wrap align-items-center card-box cursor-pointer"
                                    onClick={() => {
                                      showAccountDetails(
                                        item?.uuid,
                                        item?.label
                                      );
                                    }}
                                  >
                                    <div className="col-xl-12 align-items-center">
                                      <div className="list-icons ">
                                        {shortName(item?.label)}
                                      </div>
                                      <div className="info mb-3 mt-3">
                                        <h4 className="fs-20 mb-0 text-black text-capitalize">
                                          {item?.label}
                                        </h4>
                                        <span>
                                          xxxx-
                                          {item?.finxact_party_account_num?.slice(
                                            -4
                                          )}
                                        </span>
                                        <h5 className="mt-2">${item?.remaining_balance? Number(item?.remaining_balance).toLocaleString() : 0.00}</h5>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {data?.account?.length == 0 && (
                            <p className="">No Data Found</p>
                          )}
                          <div className="col-md-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={dataLoading}
      />
    </>
  );
};

export default Accounts;
