import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import endPoints from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import useFetch from '../hooks/useFetch';
import Sidebar from '../components/Sidebar';

const IntraPayOut = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    // console.log(location?.state, "dddddddddd");

    const [details, setDetails] = useState({
        "label": "",
        "account_number": "",
        "amount": "",
        "purpose": "",
        "account_id": "",
        "contact_id": "",
        "sent_from": ""
    });
    const [errors, setErrors] = useState({});
    const [payLoading, setPayLoading] = useState(false);

    let accID = params?.b_id?.includes("_") ? params?.b_id?.split("_")[0] : params?.acc_id;
    let contactID = params?.acc_id?.includes("_") ? params?.acc_id?.split("_")[0] : params?.b_id;
    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${accID}`);
    useEffect(() => {
        if (contacts && contactID) {
            let findContact = contacts?.data?.find((item) => {
                if (item?.attributes?.uuid == contactID) {
               
                    return item
                }
            });
       
            let label = `${findContact?.attributes?.first_name ?? ''} ${findContact?.attributes?.last_name ?? ''}`
            setDetails({
                ...details,
                "label": label?.trim() ??"",
                "amount": "",
                "purpose": "",
                "sent_from": findContact?.attributes?.name,
                "account_number": findContact?.attributes?.accountNickName??"",
                "account_id": accID,
                "contact_id": contactID,
            })
        }
    }, [contacts,contactID]);

    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);

    const handleChange = (name,value) => {
 
        setDetails({ ...details, [name]: value });

        if (name === "label" && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
       
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate all fields are filled
        let valid = true;
        let newErrors = {};

        for (const key in details) {
            if (details[key] === "") {
                newErrors[key] = `${key.replace("_", " ")} is required`;
                valid = false;
            }
        }

        // If there are validation errors, set the error state and return early
        if (!valid) {
            setErrors(newErrors);
            return;
        }
        try {
            setPayLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MAKE_PAY_VIA_CORE}`, details,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setPayLoading(false);
            if (resp?.status == 200) {
                toast.success("Amount Transferred Successfully");
                navigate(`/contact-details/${params?.b_id}/${params?.acc_id?.split("_")[0]}`)
            }
        } catch (error) {
            
            if (error.response.status == 401) {
                localStorage.clear();
                
                navigate("/login");
                return;
            }
            setPayLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }

    return (
        <>
            <MainHeader />
            <div className="authincation content-body default-height">
                <div className="container-fluid h-100">
                    <div className="row ">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Intrabank Transfer</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">

                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Name</label>
                                                                <input
                                                                    value={details?.label?.trim()}
                                                                    onChange={(e) => handleChange("label", e.target.value)}
                                                                    name="label"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Name"
                                                                />
                                                                {errors.label && <span className="text-danger">{errors.label}</span>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Account Code</label>
                                                                <input
                                                                    value={details?.account_number?.trim()}
                                                                    onChange={(e) => handleChange("account_number", e.target.value)}

                                                                    name="account_number"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Account Code"
                                                                />
                                                                {errors.account_number && <span className="text-danger">{errors.account_number}</span>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Amount</label>

                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    name="amount"
                                                                    prefix="$"
                                                                    placeholder="Amount"
                                                                    defaultValue={details.amount}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(value, name, values) => {
                                                                        setDetails(prev => ({ ...prev, amount: value }));
                                                                        handleChange("amount",value);
                                                                    }}
                                                                />
                                                                {errors.amount && <span className="text-danger">{errors.amount}</span>}
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="mb-1 form-label"> Purpose</label>
                                                                <textarea
                                                                    onChange={(e)=>handleChange("purpose",e.target.value)}
                                                                    name="purpose"
                                                                    className="form-control"
                                                                    rows={2}
                                                                    placeholder="Purpose"
                                                                    spellCheck="false"
                                                                    defaultValue={""}
                                                                />
                                                                {errors.purpose && <span className="text-danger">{errors.purpose}</span>}
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={payLoading}>
                                                                    {payLoading ? "Transferring..." : "Submit"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default IntraPayOut
