

import useFetch from "../hooks/useFetch";
import endPoints from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import MainHeader from "../components/MainHeader";
import { formatCurrency } from "../hooks/dollarFormatter";

import FetchStaffCards from "./FetchStaffCards";
import { useEffect, useState } from "react";
import { decryptData } from "../hooks/encryptionUtils";
import { useAuth } from "../components/AuthContext";
import Sidebar from "../components/Sidebar";
const Dashboard = () => {
  const navigate = useNavigate();
  // console.log("process.env.BASE_URL");
  const { userRole } = useAuth();
  const [fetchBusiness, setFetchBusiness] = useState(false);
  const [decryptedrRole, setDecryptedRole] = useState(null);
  const [customerGroup, setCustomerGroup] = useState(false);
  const showAccount = (item) => {
    let slug = item?.uuid + "_" + item?.legal_name?.trim()?.replaceAll(" ", "-");
    navigate(`/${slug}/accounts`, { state: { uuid: item?.uuid, item, slug } });
  }
  const handleDecrypt = () => {
    let user_role = localStorage.getItem("skyrole")
    
    // console.log("itemmm=========name", item);
    if (user_role) {
      try {
        const decrypted = decryptData(user_role);
        setDecryptedRole(decrypted);
        if (decrypted == "card_only") setFetchBusiness(false);
        else setFetchBusiness(true);
      
      } catch (error) {

        console.error('Decryption error:', error.message);
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  useEffect(() => {

    handleDecrypt();

  }, [])

  const shortName = (name) => {
    name = name?.split(" ");
    // console.log("name");
    let res = name?.length > 1 ? name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase() : name[0][0]?.toUpperCase()
    return res
  }
  // console.log(data);
  const { data, dataLoading } = useFetch(endPoints.ALL_BUSINESS, fetchBusiness);

  useEffect(() => {
    let userData = localStorage.getItem('user');
    
    if (userData) {
      try {
        const decrypted = decryptData(userData);
     
      if (data?.length > 0 && decrypted?.customerGroup == 2) {
        setCustomerGroup(false);
        }
        else setCustomerGroup(true);
    } catch (error) {
      console.error('Decryption error:', error.message);
      localStorage.clear();
      navigate("/login");
    }
  }
   
      
      }, [data])
      
   



  return (
    <>
      <MainHeader />
      <div className="content-body default-height">
        {/* row */}
        {!dataLoading && decryptedrRole != "card_only" &&
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-xl-10 col-md-9 m-auto main-page">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card plan-list">
                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                        <div className="me-auto pe-3 mb-3">
                          <h4 className="text-black fs-26 mb-0">Businesses</h4>
                        </div>
                        {userRole != "admin" && userRole != "edit_account" && userRole != "view_account" && userRole != "card_only" && customerGroup && <div className=" create-account">
                          <Link to={"/create-business"}>Add New Business</Link>
                        </div>}
                      </div>
                      <div className="card-body  pt-2">
                        <div className="row">
                          {data?.length > 0 && data?.map((item, ind) => {
                            // console.log(item);
                            return (
                              <div className="col-md-4 col-sm-6 cursor-pointer" key={ind} onClick={() => showAccount(item)}>
                                <a >
                                  <div className="border flex-wrap pt-3 list-row align-items-center mb-2 dash-box">
                                    <div className="col-xl-12 align-items-center">
                                      <div className="list-icons">{shortName(item?.title ?? item?.legal_name)}</div>
                                      <div className="info mb-3 mt-3">
                                        <h4 className="fs-24 mb-0 text-black text-capitalize">
                                          {item?.title ?? item?.legal_name}
                                        </h4>
                                        {/* <span>Lorem ipsum dolor sit amet</span> */}
                                      </div>
                                    </div>
                                    <div className="activities ps-3 pe-3 col-12">
                                      <h3 className="fs-16 mb-0 mob-value">Total Value</h3>
                                      <h3 className="text-info fs-28">{formatCurrency(item?.total_remaining_balance ?? 0)}</h3>
                                    </div>
                                  </div>
                                </a>
                              </div>

                            )
                          })
                          }
                          {data?.length == 0 && <p className="">No Data Found</p>}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {!dataLoading && decryptedrRole == "card_only" && <FetchStaffCards />}
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={dataLoading}
      />
    </>
  )
}

export default Dashboard