import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useParams } from 'react-router-dom';
import formatDate from '../hooks/formattDate';
import { Bars } from 'react-loader-spinner';
import Pagination from '../components/Pagination';
import useDownload from '../hooks/useDownload';
import { toast } from 'react-toastify';
import { getCurrentDate, getFirstDateOfMonth } from '../hooks/getDates';
import TransactionsHelper from '../hooks/TransactionsHelper.tsx';
import TransactionTile from '../components/TransactionTile.jsx';
import axios from 'axios';
import Sidebar from '../components/Sidebar.jsx';

const CardTransaction = () => {
    const location = useLocation();
    const params = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [csvLoading,setCsvLoading]=useState(false);
    const [perPage, setPerPage] = useState(20);

    const [date, setDate] = useState({
        fromDate: null,
        toDate: null
        // fromDate: getFirstDateOfMonth(),
        // toDate: getCurrentDate()
    });

    const [fetchData, setFetchData] = useState(false);
    const [filteredCards, setFilteredCards] = useState(null);
    const isContactId = params?.id?.endsWith('_contact'); // Adjust the logic based on your ID patterns
    const isCardId = params?.id?.endsWith('_card');
    let queryParams = `?&page=${currentPage}&per_page=${perPage}`
    if (params?.acc_id) {
        queryParams += `&account_id=${params?.acc_id?.split("_")[0]}`
    }
    if (isContactId) {
        queryParams += `&contact_id=${params?.id?.split("_")[0]}`
    }
    if (isCardId) {
        queryParams += `&card_id=${params?.id?.split("_")[0]}`
    }

    if (date?.fromDate && date?.toDate) {
        queryParams += `&from=${date?.fromDate}&to=${date?.toDate}`
    }
    useEffect(() => {
        if (fetchData) {
            setFetchData(false);
        }
    }, [fetchData]);

    const [count, setCount] = useState(1);
    const { data: allAccountsData, dataLoading: allAccountsDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}/${params?.acc_id?.split("_")[0]}`);

    const { data: cards, dataLoading: cardsLoading } = useFetch(`${endPoints.CARD_TRANSACTION}${queryParams}`, count);
    // const { data: csv, dataLoading: cscLoading } = useDownload(`${endPoints.CSV_TRANSACTION_EXPORT}?from=${date?.fromDate}&to=${date?.toDate}&account_id=${params?.acc_id?.split("_")[0]}`, fetchData);
    useEffect(() => {
        setFilteredCards(cards?.data?.data)

    }, [cards]);


    const handleSearch = () => {
        if (!date?.fromDate || !date?.toDate) {
            toast.error("Both Dates Required");
            return;
        }
        setCount(count + 1)
        setCurrentPage(1)
    }
    const handleCsvExport = async () => {
        if (!date?.fromDate || !date?.toDate) {
            toast.error("Both Dates Required");
            return;
        }
        try {
            setCsvLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            // setDataLoading(true);
            const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.CSV_TRANSACTION_EXPORT}?from=${date?.fromDate}&to=${date?.toDate}&account_id=${params?.acc_id?.split("_")[0]}`, {
                headers: {
                    'Authorization': `Bearer ${tk.access_token}`
                }
            });
            if (resp?.status == 200) {

                const link = document.createElement('a');
                link.href = `${resp?.data?.transaction_url}`;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            setCsvLoading(false);
        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data)
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }

    }
    const filterCards = (type) => {
        if (type == "CLEAR") {
            setFilteredCards(cards?.data?.data)
            return
        }
        const filter = cards?.data?.data?.filter((item) => {
            if (item?.attributes?.status == type) {
                return item
            }
        });
        setFilteredCards(filter)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setCount(count + 1);
        
    };

    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in yyyy-mm-dd format

    return (
        <>
            <MainHeader />

            {!cardsLoading && <div className="content-body default-height">

                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-xl-10 col-md-9 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">Card Transaction</h4>
                                            </div>
                                            <div className="export-btn">
                                                <a onClick={handleCsvExport} className='cursor-pointer'>
                                                    {/* {cscLoading ? "Loading" : "Export"} */}
                                                    Export
                                                    <svg
                                                        width={17}
                                                        height={16}
                                                        viewBox="0 0 17 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clipPath="url(#clip0_194_116)">
                                                            <path
                                                                d="M3.44333 5.83203L1.86292 6.88533C1.4324 7.17327 1.21622 7.31677 1.05984 7.50627C0.921851 7.67453 0.818382 7.86834 0.755347 8.07662C0.683594 8.31212 0.683594 8.57153 0.683594 9.08944V12.3818C0.683594 13.3091 0.683594 13.7727 0.863897 14.1269C1.02268 14.4386 1.27612 14.6921 1.58787 14.8509C1.94204 15.0312 2.40567 15.0312 3.33294 15.0312H13.6728C14.6 15.0312 15.0637 15.0312 15.4178 14.8509C15.7296 14.6921 15.983 14.4386 16.1418 14.1269C16.3221 13.7727 16.3221 13.3091 16.3221 12.3818V9.08944C16.3221 8.57153 16.3221 8.31212 16.2504 8.07754C16.1873 7.86926 16.0839 7.67545 15.9459 7.50719C15.7895 7.31677 15.5733 7.17327 15.1428 6.88533L13.5624 5.83203"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                            <path
                                                                d="M16.3221 9.51164H13.969C13.3287 9.51164 13.0086 9.51164 12.7676 9.6855C12.5266 9.85845 12.4254 10.162 12.223 10.7692L12.142 11.0129C11.9397 11.6201 11.8385 11.9237 11.5974 12.0984C11.3564 12.2714 11.0363 12.2714 10.396 12.2714H6.6106C5.97034 12.2714 5.65021 12.2714 5.40919 12.0975C5.16817 11.9246 5.06698 11.621 4.8646 11.0139L4.78365 10.7701C4.58127 10.1629 4.48008 9.85937 4.23906 9.68458C3.99804 9.51164 3.67791 9.51164 3.03765 9.51164H0.683594M6.20307 3.07224L8.50286 0.772461M8.50286 0.772461L10.8026 3.07224M8.50286 0.772461V7.21185"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_194_116">
                                                                <rect width={17} height="15.6753" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="search-row dropdown">
                                                        <div className="search-dt">
                                                            <input
                                                                max={currentDate}
                                                                value={date?.fromDate}
                                                                onChange={(e) => { setDate({ ...date, fromDate: e.target.value }) }}
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="From"
                                                            />
                                                            <input
                                                                max={currentDate}
                                                                value={date?.toDate}
                                                                onChange={(e) => { setDate({ ...date, toDate: e.target.value }) }}
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="to"
                                                            />
                                                            <button type="submit" className="" onClick={handleSearch}>
                                                                Search
                                                            </button>
                                                        </div>

                                                        <button class="filter dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.66797 18.0002V6.54346M25.3346 29.457V25.1607M6.66797 29.457V23.7286M25.3346 19.4323V6.54346M16.0013 10.8398V6.54346M16.0013 29.457V16.5681" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M6.66667 23.7284C8.13943 23.7284 9.33333 22.446 9.33333 20.8642C9.33333 19.2823 8.13943 18 6.66667 18C5.19391 18 4 19.2823 4 20.8642C4 22.446 5.19391 23.7284 6.66667 23.7284Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M15.9987 16.5682C17.4715 16.5682 18.6654 15.2859 18.6654 13.704C18.6654 12.1222 17.4715 10.8398 15.9987 10.8398C14.5259 10.8398 13.332 12.1222 13.332 13.704C13.332 15.2859 14.5259 16.5682 15.9987 16.5682Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                                <path d="M25.3346 25.1605C26.8074 25.1605 28.0013 23.8782 28.0013 22.2963C28.0013 20.7145 26.8074 19.4321 25.3346 19.4321C23.8619 19.4321 22.668 20.7145 22.668 22.2963C22.668 23.8782 23.8619 25.1605 25.3346 25.1605Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" />
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item cursor-pointer"  onClick={() => { filterCards("COMPLETION") }}>Completion</a>
                                                            <a class="dropdown-item cursor-pointer"  onClick={() => { filterCards("PENDING") }}>Pending</a>
                                                            <a class="dropdown-item cursor-pointer"  onClick={() => { filterCards("CLEAR") }}>Clear Filter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mt-4">

                                                {filteredCards?.length > 0 && filteredCards?.map((item, id) => {

                                                    let getTransactionType = TransactionsHelper.getTransactionType(item?.attributes)
                                                    let transactionType = getTransactionType + " " + `${getTransactionType == "ACH" ? TransactionsHelper.isDebit(item?.attributes, location?.state?.accountId) ? "OUT" : "IN" : ""}`

                                                    return (
                                                        <TransactionTile item={item} accountId={allAccountsData?.data?.id} />
                                                    )
                                                })
                                                }
                                                {filteredCards?.length == 0 && <p className="">No Data Found</p>}
                                                {filteredCards?.length > 0 && <Pagination totalPages={cards?.total_pages} onPageChange={handlePageChange} current={currentPage} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={cardsLoading}
            />

        </>
    )
}

export default CardTransaction